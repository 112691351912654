<template>
	<div>
		<a-row
			type="flex"
			justify="start"
			:gutter="[32, 32]"
			style="margin-bottom: 16px;"
		>
			<a-col>
				<DailyCard />
			</a-col>
			<a-col>
				<MonthlyCard />
			</a-col>
			<a-col>
				<ResumeCard />
			</a-col>
		</a-row>
		<a-row type="flex" justify="start" :gutter="[32, 32]">
			<a-col>
				<YearlyCard />
			</a-col>
			<!-- <a-col>
				<TablesRankingCard />
			</a-col> -->
		</a-row>
	</div>
</template>

<script>
import {
	DailyCard,
	MonthlyCard,
	YearlyCard,
	ResumeCard,
	// TablesRankingCard,
} from './components'

export default {
	name: 'FinancialReport',
	components: {
		DailyCard,
		MonthlyCard,
		YearlyCard,
		ResumeCard,
		// TablesRankingCard,
	},
}
</script>
